<template>
  <v-app>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <v-card
          class="mx-auto text-center align-center"
          max-width="500"
          elevation="0"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                www.elolostudios.com
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({}),
};
</script>
